import React,{useState, useRef, useEffect} from "react"
import Cookies from 'universal-cookie';

const PopupMessage = ({ popupMessagePost }) => {

  const cookies = new Cookies();
  const [clicked, setClicked] = useState(true);

function closeDetails(index) {
  setClicked(false);
  cookies.set('modal_message', 'closed', { path: '/' });
}
console.log(clicked)
   useEffect(() => {
     if(cookies.get('modal_message')==='closed'){
       setClicked(false);
     }
     console.log(cookies.get('modal_message'))
   }, [setClicked]);

  return (
    <>
    <div id="covid" className={`${clicked ? 'modal active' : 'modal not-active'}`} style={{display: clicked ? 'inline-block' : 'none'}}>
  <div dangerouslySetInnerHTML={{ __html: popupMessagePost.content }}/>
    <a href="#" onClick={() => closeDetails()} className="close-modal">Close</a></div>
    </>
  )
}

export default PopupMessage
